import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <div className="w-full h-screen flex flex-col items-center justify-center px-6">
      <div>
        <h1 className="text-6xl font-extrabold text-center mb-6">404: Not Found</h1>
        <p className="text-lg font-semibold text-center">You just hit a route that doesn&#39;t exist... the sadness.</p>
        <Link to="/">
          <p className="text-center mt-4 uppercase underline">Go to Home Page</p>
        </Link>
      </div>
    </div>
  </>
)

export default NotFoundPage
